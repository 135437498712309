<template>
    <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
            <v-card-title class="headline">Удаление заказа</v-card-title>
            <v-card-text>Вы на самом деле хотите удалить заказ <b>{{view.name}}</b>, клиента
                    {{view.customer_name}} ({{view.customer_phone}})?</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn  flat="flat" @click.native="$emit('close')">Отмена</v-btn>
                <v-btn color="error" dark @click.native="save">Удалить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['view'],
    data(){
        return{
            dialog:true
        }
    },
    computed: {

    },
    mounted:function(){
//        console.log(this.view)
    },
    methods: {
        save: function() {
            var t=this;
            this.$http.post(this.$store.state.apiUrl+'job/delete/',{
                id: this.view.id
            })
            .then(
                (response) => {
                    t.$store.dispatch('setMessage',{
                        'type':response.body.err==1?'error':'success',
                        'message':response.body.msg
                    })
                    if (!response.body.err){
                        this.$emit('close','reload');
                    }
                }, 
                (err) => { 
                    console.log(err)
                })

//            console.log(this.resources);
//            console.log(this.view);            
        }
    }
}
</script>

<style>

</style>
