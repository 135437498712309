<template>
  <div>
    <v-toolbar>
      <h4>Заказы</h4>
      &nbsp;
      <v-icon @click="filterShow = !filterShow">fa-filter</v-icon>
      <v-btn x-small v-for="f in filterList" :key="f.id" @click="setFilter(f)">{{ f.name }}</v-btn>
      <v-spacer></v-spacer>

      <v-autocomplete
        v-model="searchId"
        :loading="isLoading"
        :search-input.sync="search"
        :items="searchJob"
        item-text="fulltxt"
        item-value="id"
        label="Найти"
        return-object
        append-icon="fa-search"
        @input="service = []"
        @change="editJob(searchId)"
        dense
        auto-select-first
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              К сожалению ничего не найдено
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:item="data">
          <v-list-item-content @click="editJob(data.item)">
            <v-list-item-title
              >{{ data.item.name }}
              <v-chip :color="data.item.status_cls" label x-small outlined>{{ data.item.status_name }}</v-chip>
              Стоимость <b>{{ data.item.cost }}</b
              >; Дата: {{ data.item.dte_create }}</v-list-item-title
            >
            <v-list-item-subtitle v-text="data.item.items"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>

      <v-btn small color="success" @click="addJob"> <v-icon dark>fa-plus-thick</v-icon>Добавить </v-btn>
      <v-btn small @click="print">
        <v-icon>fa-print</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card v-if="filterShow">
      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="filterStatus"
              :items="sstatus"
              label="Cтатус"
              multiple
              dense
              item-text="name"
              item-value="id"
              clearable
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0 && filterStatus.length == 1">{{ item.name }}</span>
                <span v-if="index === 0 && filterStatus.length > 1">{{ filterStatus.length }} Статусов</span>
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="filterPeriod"
              :items="periods"
              label="Даты заказа"
              dense
              item-text="name"
              item-value="id"
              return-object
              clearable
            >
            </v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="filterTpe"
              :items="tpes"
              label="Тип заказа"
              dense
              item-text="name"
              item-value="id"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="filterUser"
              :items="users"
              label="Приемщик"
              dense
              item-text="fio"
              item-value="id"
              clearable
            >
            </v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="filterWorker"
              :items="users"
              label="Исполнитель"
              dense
              item-text="fio"
              item-value="id"
              clearable
            >
            </v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="filterFinish"
              :items="finishs"
              label="Дата выполнения"
              dense
              item-text="name"
              item-value="id"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row align="center">
          <v-btn @click="dataLoad" color="success">Применить</v-btn>
          <div class="flex-grow-1"></div>
          <v-text-field label="Название фильтра" v-model="filterName" hide-details></v-text-field>
          <v-btn @click="saveFilter">Сохранить</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card color="primary" dark v-show="!!isLoadingData">
      <v-card-text>
        Данные загружаются
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>

    <v-data-table
      dense
      v-bind:headers="headers"
      :items="tableData"
      :items-per-page="15"
      :footer-props="{
        'items-per-page-options': [15, 30, 45, 60, 75, 90],
      }"
      v-show="!!!isLoadingData"
    >
      <template v-slot:item.name="{ item }">
        <a href="#" @click="editJob(item)">{{ item.name }}</a>
      </template>
      <template v-slot:item.status_name="{ item }">
        <v-menu top>
          <template v-slot:activator="{ on }">
            <v-chip :color="item.status_cls" label x-small outlined v-on="on">{{ item.status_name }}</v-chip>
          </template>

          <v-list dense outlined subheader>
            <v-list-item v-for="(st, index) in sstatus" :key="index" @click="saveStatus('job', st.id, item.id)">
              <v-list-item-title>{{ st.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.history="{ item }">
        <v-icon @click="showLog(item.id)">fa-history</v-icon>
      </template>
      <template v-slot:item.dte_finish="{ item }">
        <v-chip v-if="item.dte_finish" :color="item.flg_expired == 1 ? 'error' : 'success'">
          {{ item.dte_finish }}
        </v-chip>
      </template>
    </v-data-table>
    <span v-show="!!!isLoadingData">
      Заказов:<b>{{ dataStat.cnt }}</b> Сумма:<b>{{ dataStat.cost }}</b> Долг:<b>{{ dataStat.credit }}</b>
    </span>

    <modal_edit
      v-if="modal"
      v-bind:title="modalTitle"
      v-bind:view="modalView"
      v-on:close="modalClose"
      v-on:delete="deleteJob"
    ></modal_edit>
    <modal_delete v-if="modal_delete" v-bind:view="modalView" v-on:close="modalDelClose"></modal_delete>
    <modal_log v-if="modal_log" v-bind:id="modalView" v-on:close="modalLogClose"></modal_log>
  </div>
</template>

<script>
import modal_edit from './job_edit.vue'
import modal_delete from './job_delete.vue'
import modal_log from './job_log.vue'
// eslint-disable-next-line no-unused-vars
import moment from 'moment'
export default {
  data() {
    return {
      filterShow: false,
      headers: [
        {
          text: 'Номер',
          align: 'left',
          value: 'name',
          sort: this.sortnum,
        },
        { text: 'Статус', value: 'status_name' },
        { text: 'История', value: 'history', sortable: false },
        { text: 'Клиент', value: 'customer_name' },
        { text: 'Телефон', value: 'customer_phone' },
        { text: 'Срок', value: 'dte_finish' },
        { text: 'Мастер', value: 'master_fio' },
        { text: 'Стоимость', value: 'cost' },
        { text: 'Долг', value: 'credit' },
      ],

      tableData: ['load'],
      modal: '',
      modal_delete: '',
      modal_log: '',
      modalTitle: '',
      modalView: [],
      jobCnt: [],
      status: 1,
      search: '',
      searchJob: [],
      searchId: [],
      isLoading: false,
      filterStatus: [],
      filterPeriod: '',
      filterTpe: '',
      filterUser: '',
      filterWorker: '',
      filterName: '',
      filterId: '',
      filterList: [],
      filterFinish: '',
      finishs: [
        { id: 7, name: 'Неделя' },
        { id: 3, name: '3 дня' },
        { id: 2, name: '2 дня' },
        { id: 1, name: '1 день' },
        { id: 0, name: 'Сегодня' },
        { id: -1, name: 'Просрочен' },
      ],
      isLoadingData: true,
    }
  },
  watch: {
    search(val) {
      var t = this
      //                if (this.isLoading)
      //                    return
      if (!!val && val.length > 1) {
        this.isLoading = true
        t.$http
          .post(t.$store.state.apiUrl + 'job/search/', {
            txt: val,
          })
          .then(
            response => {
              t.searchJob = response.body
              console.log('searchJob', t.searchJob)
              t.isLoading = false
            },
            err => {
              console.log(err)
            },
          )
      }
    },
  },
  components: {
    modal_edit,
    modal_delete,
    modal_log,
  },
  computed: {
    dataStat: function() {
      var a = {}
      if (this.tableData) {
        a.cnt = this.tableData.length
        a.cost = 0
        a.credit = 0
        this.tableData.forEach(function(value) {
          a.cost += value.cost
          a.credit += value.credit
        })
      }
      return a
    },
    setting: function() {
      return this.$store.state.spr.setting
    },
    sstatus: function() {
      console.log('status', this.$store.state.spr.status)
      return this.$store.state.spr.status
    },
    tData: function() {
      var t = this
      console.log('t.selStatus ', t.selStatus)
      var positiveArr = this.tableData.filter(function(e) {
        if (t.selStatus == undefined || t.selStatus.length == 0) {
          return true
        } else {
          return t.selStatus.indexOf(e.status) != -1
        }
      })
      return positiveArr
      //return this.tableData;
    },
    tpes: function() {
      return this.$store.state.spr.job_tpe
    },
    users: function() {
      return this.$store.state.spr.user
    },
    periods: function() {
      var now = moment(),
        last = moment().add(-1, 'days'),
        week = moment().add(-moment().format('e'), 'days'),
        lweek = moment().add(-moment().format('e') - 7, 'days'),
        lweek2 = moment().add(-moment().format('e') - 1, 'days'),
        month = moment().add(-moment().format('D') + 1, 'days'),
        lmonth = moment()
          .add(-moment().format('D') + 1, 'days')
          .add(-1, 'month'),
        lmonth2 = moment().add(-moment().format('D'), 'days')
      moment.lang('ru')
      console.log(now.format('LLLL'))
      return [
        { id: 0, name: 'За все время', dte_start: '', dte_end: '' },
        {
          id: 1,
          name: 'Сегодня',
          dte_start: now.format('L'),
          dte_end: now.format('L'),
        },
        {
          id: 2,
          name: 'Вчера',
          dte_start: last.format('L'),
          dte_end: last.format('L'),
        },
        {
          id: 3,
          name: 'С начала недели',
          dte_start: week.format('L'),
          dte_end: now.format('L'),
        },
        {
          id: 4,
          name: 'С начала месяца',
          dte_start: month.format('L'),
          dte_end: now.format('L'),
        },
        {
          id: 5,
          name: 'За прошлую неделю',
          dte_start: lweek.format('L'),
          dte_end: lweek2.format('L'),
        },
        {
          id: 6,
          name: 'За прошлый месяц',
          dte_start: lmonth.format('L'),
          dte_end: lmonth2.format('L'),
        },
      ]
    },
  },
  mounted: function() {
    var t = this

    this.dataLoad()
    this.$store.dispatch('loadJobTpe')
    this.$store.dispatch('loadSetting')
    this.$store.dispatch('loadUser')
    this.$store.dispatch('loadStatus').then(() => {
      //   t.$store.state.spr.status.forEach(function (item, i) {
      //     //                            t.$set(t.selStatus, i, item.id);
      //   })
    })
    t.filterLoad()
  },
  methods: {
    modalClose: function(prm) {
      this.modal = ''
      this.modal_delete = ''
      if (prm == 'reload') {
        this.dataLoad()
      }
    },
    modalDelClose: function(prm) {
      this.modal_delete = ''
      if (prm == 'reload') {
        this.modal = ''
        this.dataLoad()
      }
    },
    dataLoad: function() {
      var t = this
      t.isLoadingData = true
      this.$http
        .post(this.$store.state.apiUrl + 'job', {
          status: t.filterStatus,
          period: t.filterPeriod,
          tpe: t.filterTpe,
          user: t.filterUser,
          worker: t.filterWorker,
          finish: t.filterFinish,
        })
        .then(
          response => {
            if (response.body.err > 0) {
              t.tableData = ['error']
            } else {
              t.tableData = response.body.job
            }
            t.isLoadingData = false
          },
          err => {
            console.log(err)
          },
        )
    },
    filterLoad: function() {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'job/filter').then(
        response => {
          if (response.body.err > 0) {
            console.log(response.body)
          } else {
            t.filterList = response.body
          }
        },
        err => {
          console.log(err)
        },
      )
    },
    saveFilter: function() {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'job/filterSave/', {
          status: t.filterStatus,
          period: t.filterPeriod,
          tpe: t.filterTpe,
          user: t.filterUser,
          worker: t.filterWorker,
          name: t.filterName,
          id: t.filterId,
          finish: t.filterFinish,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (response.body.err > 0) {
              console.log(response.body.err)
            } else {
              t.filterId = response.body.id
              t.filterLoad()
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    setFilter: function(f) {
      var t = this
      console.log('t.periods', t.periods)
      console.log('filter', f)
      t.filterStatus = f.status
      t.filterPeriod = t.periods.filter(e => e.id == f.period)[0]
      t.filterTpe = f.tpe
      t.filterUser = f.user
      t.filterWorker = f.worker
      t.filterName = f.name
      t.filterId = f.id
      t.filterFinish = parseInt(f.finish)
      t.dataLoad()
    },
    showJob: function(job) {
      var t = this
      if (job.listItems) {
        t.$delete(job, 'listItems')
        //                console.log(job.splice(1,1));
      } else {
        t.$set(job, 'loadItems', 1)
        this.$http.post(this.$store.state.apiUrl + 'job/load/' + job.id).then(
          response => {
            if (response.body.err > 0) {
              console.log(response.body.err)
            } else {
              t.$delete(job, 'loadItems')
              t.$set(job, 'listItems', response.body.item)
            }
          },
          err => {
            console.log(err)
          },
        )
      }
    },
    addJob: function() {
      this.modal = true
      this.modalTitle = 'Добавление заказа'
      this.modalView = []
      //                        this.showModal = true;
      //                        this.modalContent = this.tpl_edit;
      //                      $('#'+this.tag).modal('show')
    },
    editJob: function(view) {
      this.modal = true
      this.modalTitle = 'Редактирование заказа ' + view.name
      this.modalView = view
      return false
    },
    closeJob: function(view) {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'job/Close/', {
          job: view.id,
        })
        .then(
          response => {
            if (response.body.err > 0) {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: response.body.msg,
              })
            } else {
              t.dataLoad(t.status)
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    deleteJob: function(view) {
      this.modal_delete = true
      this.modalView = view
    },
    selStatus_: function(row, item, status) {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'job/ItemStatusSave/', {
          job: row.id,
          item: item.id,
          status: status.id,
        })
        .then(
          response => {
            if (response.body.err > 0) {
              console.log(response.body.err)
            } else {
              t.$set(item, 'status_name', status.name)
              t.$set(item, 'status', status.id)
              t.$set(item, 'status_cls', status.cls)
              t.$set(row, 'status_cls', response.body.job.status_cls)
              t.$set(row, 'status_name', response.body.job.status_name)
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    showLog: function(id) {
      this.modal_log = true
      this.modalView = id
    },
    modalLogClose: function() {
      this.modal_log = ''
    },
    saveStatus(tpe, status, id) {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'job/statusSave/', {
          tpe: tpe,
          status: status,
          id: id,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              t.dataLoad()
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    print: function() {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'job', {
          status: t.filterStatus,
          period: t.filterPeriod,
          tpe: t.filterTpe,
          user: t.filterUser,
          worker: t.filterWorker,
          finish: t.filterFinish,
          getHtml: true,
        })
        .then(
          response => {
            if (response.body.err > 0) {
              console.log(response.body.err)
            } else {
              var new_win = window.open()
              var content =
                '<html><head>' + '<title>Заказы</title></head><body>' + response.body.html + '</body></html>'
              new_win.document.write(content)
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    sortnum: function(e, e2) {
      var t1 = parseInt(e.replace(/\D+/g, ''))
      var t2 = parseInt(e2.replace(/\D+/g, ''))
      return t1 - t2
    },
    openHistory: function(id) {
      console.log(id)
    },
  },
}
</script>

<style>
.job_expired > .v-data-table__wrapper > table > tbody > tr {
}
</style>
